import React, {useEffect, useState} from 'react';
import {handleRequest, handleFileRequest, isRequestSuccess} from "../../services/http-wrapper";
import fileDownload from "../../images/projects/file_download.png";
import fileLoader from "../../images/projects/loader-file.gif";
import zipArchive from "../../images/projects/zip-archive.png";

export interface IUser {
    id: number,
    name: string
};

export interface IProjectFile {
    id: number,
    name: string
}

export interface IProjectTask {
    id: number,
    name: string,
    user: IUser | undefined
}

export interface IProject {
    id: number,
    name: string,
    information: string,
    files: IProjectFile[]
    tasks: IProjectTask[]
    //TODO: add user
    user: any
}

declare module "react" {   namespace JSX {     interface HTMLTemplateElement extends HTMLElement {       shadowroot?: "open" | "closed"     }   } }

const ListProjects = () => {
    const [projects, setProjects] = useState<IProject[]>([])

    const [filesLoad, setFilesLoad] = useState<Map<IProjectFile['id'],boolean>>(new Map())
    const [isZipArchiveLoading, setIsZipArchiveLoading] = useState(false)


    const loadProjects = async () => {
        const response = await handleRequest('projects/findLinkedUser');

        setProjects(response)
    }

    useEffect(() => {
        loadProjects()
    }, []);


    //TODO:
    const onDownloadProjectFile = async(e: any) => {
        let newFilesLoad = new Map(filesLoad)
        newFilesLoad.set(Number(e.target.dataset.fileId), true)
        setFilesLoad(newFilesLoad)
        const response = await handleFileRequest('projects/downloadProjectFile',{
            projectId: Number(e.target.dataset.projectId),
            fileId: Number(e.target.dataset.fileId),
        }, e.target.dataset.fileName, 'Успешно загружено' );

        newFilesLoad = new Map(filesLoad)
        newFilesLoad.set(Number(e.target.dataset.fileId), false)
        setFilesLoad(newFilesLoad)

        // setTimeout(() => setFilesLoad(filesLoad.set(Number(e.target.dataset.fileId), false)), 50000);

    }

    //TODO:
    const onDownloadProjectFilesArchive = async(e: any) => {
        setIsZipArchiveLoading(true);

        const response = await handleFileRequest('projects/downloadProjectFilesAsArchive',{
            projectId: Number(e.target.dataset.projectId),
        }, `Исходные данные проекта - ${e.target.dataset.projectName}`, 'Успешно загружено' );

        setIsZipArchiveLoading(false);
    }

    return (
        <div className="holder mx-auto w-11/12 grid grid-cols-2">
            {
                projects.map((project, index) => (
                    <div key={index} className="each grow  mb-10 m-2 shadow-lg border-gray-800 bg-gray-100 relative">
                        {/*<img className="w-full" src="https://i.ytimg.com/vi/qew27BNl7io/maxresdefault.jpg" alt="" />*/}
                        <div className="badge absolute top-0 right-0 bg-blue-500 m-1 text-gray-200 p-1 px-2 text-xs font-bold rounded">В согласовании</div>
                        <div className="info-box text-xs flex p-1 font-semibold text-gray-500 bg-gray-300">
                            {/*TODO:*/}
                            <span className="mr-1 p-1 px-2 font-bold">Статус</span>
                            {/*<span className="mr-1 p-1 px-2 font-bold border-l border-gray-400">Статус</span>*/}
                        </div>
                        <div className="desc p-4 text-gray-800">
                            <span className="description text-sm block py-2 border-gray-400 mb-2"><span className="title font-bold">Наименование:</span> {project.name}</span>
                            {/*<a href="https://www.youtube.com/user/sam14319" target="_new" className="badge bg-indigo-500 text-blue-100 rounded px-1 text-xs font-bold cursor-pointer">@dynamo_gaming</a>*/}
                            <span className="description text-sm block py-2 border-gray-400 mb-2"><span className="title font-bold">Описание:</span> {project.information}</span>
                            <span className="description text-sm block py-2 border-gray-400 mb-2"><span className="title font-bold">Автор:</span> {project.user?.name ?? 'Не задан'}</span>
                            <span className="description text-sm block py-2 border-gray-400 mb-2"><span className="title font-bold">Подразделение:</span> {project.user?.team?.name ?? 'Не задан'}</span>
                            <span className="description text-sm block py-2 border-gray-400 mb-2"><span className="title font-bold">Задачи:</span>
                                {project.tasks? project.tasks.reverse().map((task, index) => (
                                    <div key={index}
                                       className="title block pt-2 select-text">
                                        {`${index+1}. ${task.name}`}
                                    </div>
                                )): 'Не заданы'}</span>
                            <span className="description text-sm block py-2 border-gray-400 mb-2"><span className="title font-bold">Файлы:</span>
                                {project.files.map((file, fileIndex) =>(
                                    <a key={fileIndex}
                                       data-project-id={project.id}
                                       data-file-id={file.id}
                                       data-file-name={file.name}
                                       className="title block cursor-pointer hover:underline pt-2"
                                       onClick={onDownloadProjectFile}>
                                        <img className="inline mr-3" width={'24px'} height={'24px'} src={filesLoad.get(file.id) ? fileLoader : fileDownload } alt="icon"/>
                                        {file.name}
                                    </a>
                                ))}
                                <hr className="h-px my-2 bg-gray-700 border-0 dark:bg-gray-700"/>
                                <a data-project-id={project.id}
                                   data-project-name={project.name}
                                   className="title block cursor-pointer hover:underline pt-2"
                                   onClick={onDownloadProjectFilesArchive}>
                                    <img className="inline mr-3" width={'24px'} height={'24px'} src={isZipArchiveLoading ? fileLoader : zipArchive } alt="icon"/>
                                    {"Сохранить файлы как zip-архив"}
                                </a>
                            </span>
                        </div>
                    </div>
                ))
            }

            {/*<div className="each mb-10 m-2 shadow-lg border-gray-800 bg-gray-100 relative">*/}
            {/*    <img className="w-full" src="https://i.ytimg.com/vi/qew27BNl7io/maxresdefault.jpg" alt="" />*/}
            {/*    <div className="badge absolute top-0 right-0 bg-indigo-500 m-1 text-gray-200 p-1 px-2 text-xs font-bold rounded">10:53</div>*/}
            {/*    <div className="info-box text-xs flex p-1 font-semibold text-gray-500 bg-gray-300">*/}
            {/*        <span className="mr-1 p-1 px-2 font-bold">105 views</span>*/}
            {/*        <span className="mr-1 p-1 px-2 font-bold border-l border-gray-400">105 Likes</span>*/}
            {/*        <span className="mr-1 p-1 px-2 font-bold border-l border-gray-400">105 Dislikes</span>*/}
            {/*    </div>*/}
            {/*    <div className="desc p-4 text-gray-800">*/}
            {/*        <a href="https://www.youtube.com/watch?v=dvqT-E74Qlo" target="_new" className="title font-bold block cursor-pointer hover:underline">Pubg Mobile Custom Room (Unlimited)</a>*/}
            {/*        <a href="https://www.youtube.com/user/sam14319" target="_new" className="badge bg-indigo-500 text-blue-100 rounded px-1 text-xs font-bold cursor-pointer">@dynamo_gaming</a>*/}
            {/*        <span className="description text-sm block py-2 border-gray-400 mb-2">lorem ipsum bekhum bukhum !lorem ipsum bekhum bukhum !</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default ListProjects;