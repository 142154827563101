import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react'

//Todo: fix custom.css
import "./custom.css"
import {ALERT_ERROR, ALERT_INFO, ALERT_NEUTRAL, ALERT_RANGE, ALERT_WARNING, IAlertData} from "../../services/constants";

const AlertBox = forwardRef((props: IAlertData, ref) => {

    const [isVisible, setIsVisible] = useState(false);
    const [messages, setMessages] = useState(['']);
    const [type, setType] = useState(props.alertType ?? ALERT_NEUTRAL);
    const delay = props.delay ?? 5000;

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, delay);
        return () => clearTimeout(timer);
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        setAlertMessage(messages: string[], type: ALERT_RANGE) {
            setMessages(messages)
            setType(type)
        },

        showAlert(isVisible: boolean) {
            setIsVisible(isVisible);
        }
    }))

    const alertMessage = () => {
        switch (type) {
            case ALERT_ERROR:
                return 'Ошибка'
            case ALERT_WARNING:
                return 'Предупреждение'
            case ALERT_INFO:
                return 'Информация'
            default:
                return 'Сообщение'
        }
    }

    const alertCSS = () => {
        switch (type) {
            case ALERT_ERROR:
                return 'bg-red-100  border-red-400 text-red-700'
            case ALERT_WARNING:
                return 'bg-red-100  border-red-400 text-red-700'
            case ALERT_INFO:
                return 'bg-green-100  border-green-400 text-green-700'
            default:
                return 'bg-red-100  border-red-400 text-red-700'
        }
    }

    const onCloseClick = () => {
        setIsVisible(false);
    }

    return (
        <>
            {isVisible && <div
                className={`fade-in ${isVisible ? '' : 'fade-out'} text-2xl flex absolute top-0 left-1/2 transform -translate-x-1/2 z-10`}>

                {messages.length &&
                    <div className={`border ${alertCSS()} px-4 py-3 rounded relative`}
                         role="alert">
                        <strong className="font-bold">{alertMessage()}: </strong>
                        {messages.length > 1 ?
                            messages.map((message, index) => (
                                <div key={index} className="">{message}</div>
                            )) :
                            <div className="">{messages[0]}</div>}
                        <span className="absolute top-0 bottom-0 right-0 px-1 py-1">
                            <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={onCloseClick}><title>Close</title><path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                        </span>
                    </div>
                }
            </div>
            }
        </>
    )
})

export default AlertBox;