import {createSlice, configureStore, createAction} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {ALERT_ERROR, ALERT_RANGE} from "../services/constants";

interface ModuleInfo {
    id: number,
    name: string,
    information: string
}

interface ITeam {
    id: number,
    name: string
}

interface UserInfo {
    id: number,
    email: string,
    name: string,
    team: ITeam,
    modules: ModuleInfo[],
}

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userInfo: {
            id: 0,
            email: '',
            name: '',
            team: {
                id: 0,
                name: ''
            },
            modules: [{}]
        },
        login: false,
        errorMessage: ''
    },
    reducers: {
        login: (state, userInfo: PayloadAction<UserInfo>) => {
            state.login = true
            state.userInfo = userInfo.payload;
        },
        logout: state => {
            state.login = false
        }
    }
})

export const { login, logout } = authSlice.actions

const APP_RESULT_ACTION = 'app/result/action'

interface IOperationResult {
    status: boolean,
    type: number,
    messages: Array<string>,
}

const operationResultAction = createAction(APP_RESULT_ACTION, (message:Array<string>, type: ALERT_RANGE) => {
    return {
        payload: {
            status: true,
            type: type,
            message: message,
        }
    }
})


interface IOperationResultPayload {
    type: number,
    messages: Array<string>,
}

const operationResultSlice = createSlice({
    name: 'appOperationResult',
    initialState: {
        status: false,
        type: ALERT_ERROR,
        messages: []
    } as IOperationResult,
    reducers: {
        proceedResult: (state, operationResult: PayloadAction<IOperationResultPayload>) => {
            state.messages = operationResult.payload.messages;
            state.status = true;
            state.type = operationResult.payload.type;
        },
        resolveResult: (state) => {
            state.status = false;
        }
    }
});

export const { proceedResult, resolveResult } = operationResultSlice.actions

export const store = configureStore({
    reducer: {
        authReducer: authSlice.reducer,
        appOperationResultReducer: operationResultSlice.reducer
    }
})
