import http from './http-common';
import axios, {AxiosError, AxiosResponse} from "axios";
import { useNavigate } from "react-router-dom";
import {ALERT_ERROR, ALERT_INFO, ERROR} from "./constants";
import {login as loginStore, logout, proceedResult, store} from "../store/store";

class ResponseError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ResponseError";
        this.message = message;
    }
}

const handleAuth = (response: AxiosResponse, isShowSuccessNotification: string|boolean) => {
    if(!response) {
        throw new Error('unknown error')
    }
    //TODO: msg remove
    if(response.status) {
        throw new ResponseError(response.statusText);
    }
    else if(isShowSuccessNotification){
        //systemEvents.dispatch('showMessage', { messages: 'Success', type: ALERT_INFO });
    }
}

type ITodoErrorResponse = {
    error: string,
    message: string | string[]
};

const handleRequest = async(requestName: string, data = {}, showSuccessNotification=false, showErrorNotification=true) => {
    try {
        const response: AxiosResponse = await http.post(requestName, data)

        handleAuth(response.data, showSuccessNotification)

        if(showSuccessNotification) {
            store.dispatch(proceedResult({
                type: ALERT_INFO,
                messages: ['Успешно']
            }))
        }

        return response.data;
    }
    catch (err) {
        const e = err as AxiosError;

        if(e.response?.status === 401) {
            store.dispatch(logout())
        }
        if(showErrorNotification) {
            let errorMessages = []
            const errorData = e.response?.data as ITodoErrorResponse;
            if(errorData && errorData.message) {
                if(typeof errorData.message === 'string') {
                    errorMessages.push(errorData.message);
                }
                else {
                    for(let index in errorData.message) {
                        errorMessages.push(errorData.message[index]);
                    }
                }
            }
            store.dispatch(proceedResult({
                type: ALERT_ERROR,
                messages: errorMessages
            }))
        }
        return ERROR;
    }
}

const handleFileRequest = async(requestName: string, data = {}, filename = '', showSuccessNotification:string|boolean=false, showErrorNotification=true) => {
    try {

        const response = await http.post(requestName,data,{
            baseURL: `${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_API_PREFIX}`,
            withCredentials: true,
            responseType: 'blob'
        }  )

        handleAuth(response.data, showSuccessNotification)

        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement('a');

        anchorElement.href = href;
        anchorElement.download = filename;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);

        if(showSuccessNotification) {
            store.dispatch(proceedResult({
                type: ALERT_INFO,
                messages: ['Успешно']
            }))
        }
    }
    catch (err) {
        const e = err as AxiosError;

        if(e.response?.status === 401) {
            store.dispatch(logout())
        }
        if(showErrorNotification) {
            let errorMessages = []
            const errorData = e.response?.data as ITodoErrorResponse;
            if(errorData && errorData.message) {
                if(typeof errorData.message === 'string') {
                    errorMessages.push(errorData.message);
                }
                else {
                    for(let index in errorData.message) {
                        errorMessages.push(errorData.message[index]);
                    }
                }
            }
            store.dispatch(proceedResult({
                type: ALERT_ERROR,
                messages: errorMessages
            }))
        }
        return ERROR;
    }
}

const handleFileUploadRequest = async(requestName: string, formData = {}, successNotification: string | boolean = false, showErrorNotification=true) => {

    try {
        const response = await http({
            method: "post",
            url: requestName,
            data: formData,
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
        });

        if(successNotification) {
            store.dispatch(proceedResult({
                type: ALERT_INFO,
                messages: [String(successNotification)]
            }))
        }

        return response.data;
    }
    catch (err) {
        const e = err as AxiosError;

        if(e.response?.status === 401) {
            store.dispatch(logout())
        }
        if(showErrorNotification) {
            let errorMessages = []
            const errorData = e.response?.data as ITodoErrorResponse;
            if(errorData && errorData.message) {
                if(typeof errorData.message === 'string') {
                    errorMessages.push(errorData.message);
                }
                else {
                    for(let index in errorData.message) {
                        errorMessages.push(errorData.message[index]);
                    }
                }
            }
            store.dispatch(proceedResult({
                type: ALERT_ERROR,
                messages: errorMessages
            }))
        }
        return ERROR;
    }
}

const isRequestSuccess = (response: any) => {
    return response !== ERROR;
}

export {handleAuth, handleRequest, handleFileRequest, handleFileUploadRequest, isRequestSuccess}