import StyledTaskbarApp from "./TaskbarApp.style";
import React from "react";

const TaskbarApp = ({ icon, ...rest }) => {
  return (
    <StyledTaskbarApp
      {...rest}
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: "0%" }}
      exit={{ opacity: 0, y: "100%" }}
    >
     <img width={'24px'} height={'24px'} src={icon} alt="icon"/>
    </StyledTaskbarApp>
  );
};

export default TaskbarApp;
