import { useState, useMemo } from "react";
import Clock from "../apps/Clock/Clock";
import Calculator from "../apps/Calculator/Calculator";
import database from "../images/database.png";
import planning from "../images/planning.png";
import reliability from "../images/reliability.png";
import success from "../images/success.png";
import clock from "../images/clock.png";
import settings from "../images/settings.png";
import calculator from "../images/calculator.png";
import fight from "../images/fight.png";
import profile from "../images/profile.png";
import projects from "../images/projects/projects.png";
import Profile from "../apps/Profile/Profile";
import CreateProject from "../apps/Projects/CreateProject";
import ListProjects from "../apps/Projects/ListProjects";
import Users from "../apps/admin/Users/Users";
import Kanban from "../apps/Kanban/Kanban";
export const apps = [
  {
    name: "Calculator",
    icon: calculator,
     width: 350,
     height: 500,
     app: Calculator,

  },
];

export const useApps = () => {
  const [openApps, setOpenApps] = useState([]);
  const [focusedApp, setFocusedApp] = useState(null);
  const [openWindows, setOpenWindows] = useState([]);
  const [installedApps] = useState(apps);
  const systemApps = useMemo(
    () => [
      {
        name: "Clock",
        icon: clock,
        width: 450,
        height: 250,
        app: Clock,
      },
      {
        name: "Settings",
        icon: settings,
        width: 700,
        height: 500,
      },

    ],
    []
  );
  const externalApps = useMemo(
      () => [
        {
          name:'Маркет проектов',
          icon:projects,
          id: 'projects',
          width: 800,
          minWidth: 800,
          height: 800,
          app: ListProjects
        },
        {
          name:'Управление проектами',
          icon:reliability,
          id: 'createProject',
          minWidth: 450,
          width: 700,
          height: 800,
          app: CreateProject
        },
        {
          name:'Личный кабинет',
          icon:profile,
          id: 'profile',
          width: 700,
          height: 600,
          app: Profile
        },
        {
          name:'Пользователи',
          icon:fight,
          id: 'users',
          width: 700,
          height: 600,
          app: Users
        },
        {
          name:'Выполнение проекта',
          icon:fight,
          id: 'kanban',
          width: 1000,
          height: 900,
          app: Kanban
        },
      ],
      []
  );

  let allAppHeight = 0
  externalApps.forEach((app) => {
    allAppHeight += app.height
  })


  const [windowPos, setWindowPos] = useState({});

  const openApp = (app) => {
    if (openApps.find((openApp) => openApp.name === app.name)) {
      focusApp(app);
      return;
    }
    setOpenApps((appsList) => {
      return [...appsList, app];
    });
    setOpenWindows((windowsList) => {
      const zIndex = windowsList.length + 1;
      return [...windowsList, { ...app, zIndex }];
    });
    setFocusedApp(app);
  };

  const focusApp = (app) => {
    if (openWindows.length > 1 && focusedApp?.name !== app.name) {
      setFocusedApp(app);
      const newWindows = [...openWindows];
      newWindows.forEach((win) => {
        if (win.name === app.name) {
          win.zIndex = newWindows.length + 1;
        } else if (win.zIndex > 1) {
          win.zIndex -= 1;
        }
      });
    }
  };

  const closeApp = (app) => {
    setOpenApps((appsList) => {
      return appsList.filter((openApp) => openApp.name !== app.name);
    });
    setOpenWindows((windowsList) => {
      const newWindows = windowsList.filter(
        (openWindow) => openWindow.name !== app.name
      );
      if (newWindows.length === 0) {
        return newWindows;
      }
      if (focusedApp?.name === app.name) {
        const newApp = newWindows.find(
          (win) => win.zIndex >= newWindows.length
        );
        setFocusedApp(newApp);
      }
      return newWindows;
    });
  };

  const maximizeApp = (app) => {
    app.height = 700
  };

  const centerWindows = () => {
    setWindowPos({});
    setTimeout(() => {
      setWindowPos({x: window.innerWidth / 2, y: window.innerHeight / 2});
    }, 100);
  };

  return {
    focusApp,
    openApps,
    openApp,
    installedApps,
    systemApps,
    externalApps,
    openWindows,
    focusedApp,
    closeApp,
    maximizeApp,
    centerWindows,
    windowPos,
  };
};
