import StyledDesktop from "./Desktop.style";
import Taskbar from "../Taskbar/Taskbar";
import AppIcon from "../AppIcon/AppIcon";
import Window from "../Window/Window";
import { useApps} from "../../hooks/useApps";
import React, {useEffect, useRef} from "react";
import { AnimatePresence } from "framer-motion";
import {resolveResult, store} from "../../store/store";
import AlertBox from "../Alert/AlertBox";
import {ALERT_ERROR} from "../../services/constants";

const Desktop = () => {
  const appsManager = useApps();
  const constraintsRef = useRef(null);
  let childAlertRef = useRef()

  store.subscribe(() => {
    const opResReducer = store.getState().appOperationResultReducer;
    if(opResReducer.status) {
      // @ts-ignore;
      childAlertRef.current.setAlertMessage(opResReducer.messages, opResReducer.type);
      // @ts-ignore;
      childAlertRef.current.showAlert(true);
      store.dispatch(resolveResult())
    }
  })

  return (
    <StyledDesktop
      initial={{ opacity: 0, scale: 0.8, borderRadius: "10px" }}
      animate={{ opacity: 1, scale: 1, borderRadius: "0px" }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ type: "spring", mass: 0.6, stiffness: 200, damping: 20 }}
      ref={constraintsRef}
    >
      <AnimatePresence>
        {appsManager.openWindows.map((app) => {
          return (
              <Window
                  key={app.name}
                  {...app}
                  appsManager={appsManager}
                  constraintsRef={constraintsRef}
              />
          );
        })}
      </AnimatePresence>
      <AlertBox ref={childAlertRef} alertType={ALERT_ERROR}/>
      {appsManager.externalApps.map((app) => {
        if(store.getState().authReducer.userInfo.modules.find((element) => element.name === app.id))
          return(
              <AppIcon
                  key={app.name}
                  {...app}
                  appsManager={appsManager}
                  constraintsRef={constraintsRef}
                  onClick={()=>appsManager.openApp(app)}
              />
        );
      })}


     <Taskbar appsManager={appsManager} />
    </StyledDesktop>
  );
};

export default Desktop;
