import React, {useEffect, useRef, useState} from "react";
import {login as loginStore, proceedResult, store} from "../../store/store";
import {auth, login as loginApp} from "../../services/auth";
import {isRequestSuccess} from "../../services/http-wrapper";
import AlertBox from "../Alert/AlertBox";
import {ALERT_ERROR} from "../../services/constants";

const Login = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    let childAlertRef = useRef()

    const authApp = async () => {
        const response = await auth();

        if(isRequestSuccess(response)) {
            store.dispatch(loginStore(response))
        }

    }

    useEffect(() => {
        authApp()
    }, []);

    const onLoginClick = async (e:React.MouseEvent<HTMLElement>) => {
        const response = await loginApp(login, password);

        if(isRequestSuccess(response)) {
            authApp()
        }
        else {
            // @ts-ignore;
            childAlertRef.current.setAlertMessage(['Некорректные данные'], ALERT_ERROR);
            // @ts-ignore;
            childAlertRef.current.showAlert(true);

            store.dispatch(proceedResult({
                type: ALERT_ERROR,
                messages: ['error']
            }))
        }
    }


    return (
        <>
            <div className="relative flex min-h-screen text-gray-800 antialiased flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
                <AlertBox ref={childAlertRef} alertType={ALERT_ERROR}/>
                <div className="relative py-3 sm:w-96 mx-auto text-center">
                    <span className="text-2xl font-light ">Авторизация</span>
                    <div className="mt-4 bg-white shadow-md rounded-lg text-left">
                        <div className="h-2 bg-purple-400 rounded-t-md"></div>
                        <div className="px-8 py-6 ">
                            <label className="block font-semibold"> Логин </label>
                            <input value={login} onChange={e => setLogin(e.target.value)} type="text" placeholder="логин" className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-indigo-500 focus:ring-1 rounded-md" />
                                <label className="block mt-3 font-semibold"> Пароль </label>
                                <input value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="пароль" className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-indigo-500 focus:ring-1 rounded-md" />
                                    <div className="flex justify-center items-baseline">
                                        <button type="submit" className="mt-4 bg-purple-500 text-white py-2 px-6 rounded-md hover:bg-purple-600 " onClick={onLoginClick}>Вход</button>
                                        {/*<a href="#" className="text-sm hover:underline">Forgot password?</a>*/}
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
