import React, {MouseEventHandler, useEffect, useState} from 'react';
import {handleRequest, isRequestSuccess} from "../../../services/http-wrapper";
import { Modal } from 'flowbite'

import '../../../tailwind.css';
import {auth} from "../../../services/auth";
import {login as loginStore, store} from "../../../store/store";
import {ERROR, INVALID_ID} from "../../../services/constants";

interface ITeam {
    id: number;
    name: string;
}

interface IUser {
    id: number;
    name: string;
    email: string;
    team: ITeam | undefined;
}

export type DatasetInjector<T, D extends DOMStringMap> = T & {
    dataset: D;
};


const Users = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [isEditUserVisible, setIsEditUserVisible] = useState<boolean>(false);
    const [isEditTeamVisible, setIsTeamUserVisible] = useState<boolean>(false);
    const [isLinkModulesVisible, setIsLinkModulesVisible] = useState<boolean>(false);

    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userTeam, setUserTeam] = useState(0);

    const [teams, setTeams] = useState<ITeam[]>([]);
    const [teamName, setTeamName] = useState('');

    const [linkedUser, setLinkedUser] = useState<number>(INVALID_ID)
    const [modulesValue, setModulesValue] = useState<string>('')
    const [selectedModules, setSelectedModules] = useState<number[]>([])

    const fetchUserData = async () => {
        const response = await handleRequest('users');

        setUsers(response)
    }

    const fetchTeamData = async () => {
        const response = await handleRequest('teams');

        setTeams(response)
    }

    useEffect( () => {
        fetchUserData()
        fetchTeamData()
    }, []);

    const onAddUserDialogClick = () => {
        setIsEditUserVisible(!isEditUserVisible);
    }

    const onAddTeamDialogClick = () => {
        setIsTeamUserVisible(!isEditTeamVisible);
    }

    const onLinkModulesDialogClick = () => {
        setIsLinkModulesVisible(!isLinkModulesVisible);
    }

    const onAddUserSubmitClick = async() => {
        const response = await handleRequest('users/create', {
            name: userName,
            email: userEmail,
            password: userPassword,
            team: userTeam
        }, true);

        if( response === ERROR) {
            return;
        }

        await fetchUserData();
        onCloseAddUserClick();
    }

    const onUserDeleteClick = async(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (event.target instanceof HTMLElement) {
            const response = await handleRequest('users/remove', {
                id: Number(event.target.dataset.userId)
            }, true);

            if( response === ERROR) {
                return;
            }

            await fetchUserData();
        }
    }

    const onCloseAddUserClick = () => {
        setIsEditUserVisible(false);
    }

    const onSelectTeam = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = Number(event.target.value);
        setUserTeam(value);
    }

    const onAddTeamSubmitClick = async() => {
        const response = await handleRequest('teams/create', {
            name: teamName,
        });

        await fetchTeamData();
        onCloseAddTeamClick();
    }

    const onCloseAddTeamClick = () => {
        setIsTeamUserVisible(false);
    }

    const onSelectLinkedUser = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = Number(event.target.value);
        setLinkedUser(value);
    }

    const onLinkModulesClick = async() => {

        const modulesToSend = modulesValue.split(' ').map(str => Number(str));

        const response = await handleRequest('users/link_user_modules', {
            id: linkedUser,
            modules: modulesToSend
        });

        if(response === ERROR) {
            return;
        }

        onCloseLinkModulesClick();
    }

    const onCloseLinkModulesClick = () => {
        setIsLinkModulesVisible(false);
    }

    return (
        <>
            <div id="userModal" tabIndex={-1} aria-hidden="true" className={`fixed top-0 left-0 right-0 z-50 ${isEditUserVisible ? '' : 'hidden'} w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 border-black border-2">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Пользователь
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-3 h-3 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={onAddUserDialogClick}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <form>
                                <div className="mb-6">
                                    <label htmlFor="userName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ФИО:</label>
                                    <input type="userName" id="userName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required value={userName} onChange={ e => setUserName(e.target.value)} />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="userEmail" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Логин/Почта:</label>
                                    <input type="userEmail" id="userEmail" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required value={userEmail} onChange={ e => setUserEmail(e.target.value)} />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="userPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Пароль:</label>
                                    <input type="userPassword" id="userPassword" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required value={userPassword} onChange={ e => setUserPassword(e.target.value)}/>
                                </div>
                                <label htmlFor="userTypes" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Роль пользователя:</label>
                                <select id="userTypes" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
                                    <option value="DEFAULT">Выбор роли</option>
                                    <option value="1">Админ</option>
                                    <option value="2">Куратор</option>
                                    <option value="3">Студент</option>
                                    <option value="4">Эксперт</option>
                                </select>
                                <label htmlFor="linkUserTypes" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Команда:</label>
                                <select id="linkUserTypes" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={onSelectTeam}>
                                    <option value="DEFAULT">Выбор команды</option>
                                    {
                                        teams.map((team, index) =>(
                                            <option key={index} value={team.id}>{team.name}</option>
                                        ))
                                    }
                                </select>
                            </form>
                        </div>
                        <div className="flex flex-row justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={onAddUserSubmitClick}>Создать</button>
                            <button data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={onCloseAddUserClick}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="teamModal" tabIndex={-1} aria-hidden="true" className={`fixed top-0 left-0 right-0 z-50 ${isEditTeamVisible ? '' : 'hidden'} w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 border-black border-2">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Команда
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-3 h-3 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={onAddUserDialogClick}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <form>
                                <div className="mb-6">
                                    <label htmlFor="userName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Наименование:</label>
                                    <input type="userName" id="userName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required value={teamName} onChange={ e => setTeamName(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <div className="flex flex-row justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={onAddTeamSubmitClick}>Создать</button>
                            <button data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={onCloseAddTeamClick}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="linkModulesModal" tabIndex={-1} aria-hidden="true" className={`fixed top-0 left-0 right-0 z-50 ${isLinkModulesVisible ? '' : 'hidden'} w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 border-black border-2">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Привязка модулей
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-3 h-3 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={onCloseLinkModulesClick}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <form>
                                <div className="mb-6">
                                    <label htmlFor="teamTypes" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Пользователь:</label>
                                    <select id="teamTypes" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={onSelectLinkedUser}>
                                        <option value="DEFAULT">Выбор пользователся</option>
                                        {
                                            users.map((user, index) =>(
                                                <option key={index} value={user.id}>{user.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="modulesLink" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Модули (id: 1,2,3...):</label>
                                    <input type="modulesLink" id="modulesLink" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required value={modulesValue} onChange={ e => setModulesValue(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <div className="flex flex-row justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={onLinkModulesClick}>Привязать</button>
                            <button data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={onCloseLinkModulesClick}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white flex flex-col grow">
                <div className="h-16 w-full bg-black bg-opacity-50">
                    <div className="w-full h-full flex justify-center items-center text-sm">
                        <div
                            className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                            <button className="mx-4 text-white" onClick={onAddUserDialogClick}>Добавить</button>
                            <div className=" h-8 w-px bg-gray-300"></div>
                        </div>
                        <div className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                            <button className="mx-4 text-white">Изменить</button>
                            <div className=" h-8 w-px bg-gray-300"></div>
                        </div>
                        <div className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                            <button className="mx-4 text-white">Удалить</button>
                            <div className=" h-8 w-px bg-gray-300"></div>
                        </div>
                        <div className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                            <button className="mx-4 text-white" onClick={onAddTeamDialogClick}>Добавить команду</button>
                            <div className=" h-8 w-px bg-gray-300"></div>
                        </div>
                        <div className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                            <button className="mx-4 text-white" onClick={onLinkModulesDialogClick}>Привязать модули</button>
                            <div className=" h-8 w-px bg-gray-300"></div>
                        </div>
                    </div>
                </div>
                <div className="p-10 [&>*]:font-sans flex flex-col grow">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-2 lg:-mx-4">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    #
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    ФИО
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-6 Wpy-3">
                                                    Группа
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Управление
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {users.map((user: IUser, index: number) => (
                                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {user.id}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {user.name}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {user.email}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {user.team?.name ?? 'Нет'}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                                                        <a data-user-id={user.id} className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={onUserDeleteClick}> Delete</a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Users;