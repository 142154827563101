import AppProvider from "../AppProvider/AppProvider";
import AppContent from "../AppContent/AppContent";
import Login from "../Login/Login";
import {useEffect, useState} from "react";
import {store} from "../../store/store";

const App = props => {
    const [isAuthHandled, setAuthHandled] = useState(false);

    store.subscribe(() => {
        setAuthHandled(store.getState().authReducer.login)
    })

    return (
    <AppProvider>
        <div className="fade-in">
            { isAuthHandled ? <AppContent /> : <Login /> }
        </div>
    </AppProvider>
    );
};

export default App;