import React from 'react';
import '../../tailwind.css';
import {logout} from "../../services/auth";
import {isRequestSuccess} from "../../services/http-wrapper";
import {login as loginStore, store} from "../../store/store";

const Logout = () => {
  const onLogoutClick = async() => {
    const response = await logout();

    if(isRequestSuccess(response)) {
      store.dispatch(loginStore())

      window.location.replace(process.env.REACT_APP_CLIENT_HOST);
    }
  };

  return (
      <button className="top-0 right-0 bg-gray-400 hover:bg-blue-950 text-white font-bold pt-1 px-4 rounded" onClick={onLogoutClick}>
        <div className="pb-1">Выход</div>
      </button>
  );
};

export default Logout;
