import React, {MutableRefObject, useEffect, useRef, useState} from 'react';

import '../../tailwind.css';
import {handleFileUploadRequest, handleRequest, isRequestSuccess} from "../../services/http-wrapper";
import {ButtonSpinner} from "../../components/Buttons/ButtonSpinner/ButtonSpinner";
import { Button, Modal } from 'flowbite-react';
import {TRefButtonSpinnerHandler} from "../../components/Buttons/ButtonSpinner/ButtonSpinner";
import {proceedResult, store} from "../../store/store";
import {ALERT_ERROR, ERROR, INVALID_FILTER_VALUE, INVALID_ID} from "../../services/constants";
import fileDelete from "../../images/projects/file_delete.png";
import {IProject, IProjectFile, IProjectTask} from "./ListProjects";

function useTrait <Type> (initialValue: any) {
    const [trait, updateTrait] = useState<Type>(initialValue);

    let current = trait;

    const get = () => current;

    const set = (newValue: any) => {
        current = newValue;
        updateTrait(newValue);
        return current;
    }

    return {
        get,
        set,
    }
}

const CreateProject = () => {
    const saveBtnRef = useRef() as MutableRefObject<TRefButtonSpinnerHandler>;

    const existedProjects = useTrait<IProject[]>([]);
    const [isCreateNew, setIsCreateNew] = useState<boolean>(true);
    const [selectedProject, setSelectedProject] = useState<React.ChangeEvent<HTMLSelectElement>|null>(null);
    const [selectedProjectFiles, setSelectedProjectFiles] = useState<IProjectFile[]>([]);
    const [filesLoadedRender, setFilesLoadedRender] = useState<boolean>(false);

    const [currentTaskFieldValue, setCurrentTaskFieldValue] = useState('')
    const [tasks, setTasks] = useState<IProjectTask[]>([])
    const [taskIndexToEdit, setTaskIndexToEdit] = useState<number>(INVALID_ID)
    const [taskValueToEdit, setTaskValueToEdit] = useState<string>('')

    const [projectName, setProjectName] = useState('');
    const [projectInformation, setProjectInformation] = useState('');
    const [projectFiles, setProjectFiles] = useState<File[]>([]);
    const [filesRerender, setFilesRerender] = useState<boolean>(false);

    const [isEditTaskVisible, setIsEditTaskVisible] = useState<boolean>(false);

    const loadProjects = async (callback: (() => void) | null = null) => {
        const response = await handleRequest('projects/findLinkedUser');

        existedProjects.set(response);
    }

    useEffect(() => {
        loadProjects()
    }, []);

    const onSelectProject = (e: React.ChangeEvent<HTMLSelectElement>|undefined = undefined) => {
        let currentElement: EventTarget & HTMLSelectElement | undefined  = undefined;

        if(e) {
            setSelectedProject(e);
            currentElement = e.target
        }
        else if(selectedProject) {
            currentElement = selectedProject.target
        }
        else {
            return;
        }

        //TODO:
        const isNew = currentElement.value === INVALID_FILTER_VALUE;
        setIsCreateNew(isNew)
        setProjectName(isNew ? '' : String(existedProjects.get().find(project => project.id === Number(currentElement?.selectedOptions[0].dataset.id))?.name));
        setProjectInformation(isNew ? '' : String(existedProjects.get().find(project => project.id === Number(currentElement?.selectedOptions[0].dataset.id))?.information));
        if(isNew) {
            setSelectedProjectFiles([])
            setTasks([]);
        }
        else {
            setSelectedProject(e ? e : selectedProject);
            setSelectedProjectFiles(existedProjects.get()[Number(currentElement.selectedOptions[0].dataset.index)].files)
            setTasks(existedProjects.get()[Number(currentElement.selectedOptions[0].dataset.index)].tasks);
        }
        setProjectFiles([])
    }

    const onAddTask = () => {

        if(!currentTaskFieldValue.length){
            return;
        }

        if (tasks.find((task)=>{ return task.name === currentTaskFieldValue})) {
            return;
        }

        setTasks([...tasks,{
            id: INVALID_ID,
            name: currentTaskFieldValue,
            user: undefined
        }])

        setCurrentTaskFieldValue('');
    }

    const onDeleteTask = (index: number) => {
        setTasks(tasks.filter((task, indexExisted) => indexExisted !== index));
    }

    const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if(event.target.files) {
            const files: File[] = Array.from(event.target.files);

            setProjectFiles(projectFiles.concat(files));
        }
        else {
            setProjectFiles([]);
        }
    }

    const onDeleteSelectedFileClick = (fileIndex: number) => {
        const updatedFiles = projectFiles;

        updatedFiles.splice(fileIndex, 1);
        setProjectFiles(updatedFiles);

        setFilesRerender(!filesRerender)
    }

    const onDeleteLoadedFileClick = (fileIndex: number) => {
        const updatedFiles = selectedProjectFiles;

        updatedFiles.splice(fileIndex, 1);
        setSelectedProjectFiles(updatedFiles);

        setFilesLoadedRender(!filesLoadedRender)
    }

    const onSaveClick = async() => {
        const formData = new FormData();

        formData.append("name", projectName);
        formData.append("information", projectInformation);

        if(!isCreateNew) {
            formData.append(`id`, String(selectedProject?.target.selectedOptions[0].dataset.id));
            if(selectedProjectFiles.length) {
                selectedProjectFiles.forEach((file, index) => {
                    formData.append(`existedFiles[]`, String(file.id));
                });
            }
            else {
                formData.append(`existedFiles[]`, '');
            }
        }

        tasks.forEach((task, index) => {
            formData.append(`tasks[]`, JSON.stringify(task));
        })

        projectFiles.forEach((file, index) => {
            formData.append(`files`, file, file.name);
        });

        const response = isCreateNew ?  await handleFileUploadRequest('projects/create', formData, 'Успешно создан' ) :
                                        await handleFileUploadRequest('projects/update', formData, 'Успешно обновлен' );

        if(isRequestSuccess(response)) {
            if (isCreateNew) {
                setProjectName('');
                setProjectInformation('');
                setProjectFiles([]);
                await loadProjects();
            } else {
                await loadProjects();
                onSelectProject();
            }
        }

        saveBtnRef.current.completeProcessing()

    }

    const onEditTask = (taskIndex: number, value: string) => {
        setTaskIndexToEdit(taskIndex)
        setTaskValueToEdit(value)
        setIsEditTaskVisible(true);
    }

    const onCloseTask = () => {
        setTaskIndexToEdit(INVALID_ID)
        setTaskValueToEdit('')
        setIsEditTaskVisible(false);
    }

    const onChangeTask = () => {
        tasks[taskIndexToEdit].name = taskValueToEdit;
        setTasks(tasks);
        setTaskIndexToEdit(INVALID_ID)
        setTaskValueToEdit('')
        setIsEditTaskVisible(false);
    }
    
    return (
        <>
            <Modal show={isEditTaskVisible}
                   position="center"
                   onClose={() => setIsEditTaskVisible(false)}>
                <Modal.Header className="p-5">Изменить задачу</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <textarea id="message" rows={6} className="flex h-full p-2.5 mb-5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="описание проекта..." value={taskValueToEdit} onChange={e => {setTaskValueToEdit(e.target.value)}}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className="flex flex-row justify-end">
                    <Button color="blue" onClick={onChangeTask}>
                        Сохранить
                    </Button>
                    <Button color="gray" onClick={onCloseTask}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="bg-gray-200 flex flex-col grow">

                <div className="p-10 [&>*]:font-sans flex flex-col grow">
                    <label htmlFor="myProjects" className="block mb-2 text-md font-bold text-black">Созданные проекты:</label>
                    <select id="myProjects"
                            className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={Number(selectedProject?.target.selectedOptions[0].dataset.id) ?? INVALID_FILTER_VALUE}
                            onChange={onSelectProject}>
                        <option value={INVALID_FILTER_VALUE}>Создать новый проект</option>
                        {
                            existedProjects.get().map((project, index) =>(
                                <option key={index}
                                        value={project.id}
                                        data-index={index}
                                        data-id={project.id}
                                        data-name={project.name}
                                        data-information={project.information}
                                        data-files={project.files}>
                                    {project.name}
                                </option>
                            ))
                        }
                    </select>
                    <label htmlFor="project-name-lable" className="block mb-2 text-md font-bold text-black">Наименование проекта:</label>
                    <input type="text" id="project-name-field" autoComplete={"off"} className="font-sans mb-5 border text-md rounded-lg block w-full p-2.5  text-black" placeholder="указать наименование проекта..." value={projectName} onChange={e => {setProjectName(e.target.value)}} />
                        {/*<p className="mt-2 text-2xl text-green-600 dark:text-green-500"><span className="font-medium">Alright!</span> Username available!</p>*/}

                    <label htmlFor="message" className="block mb-2 text-md text-black font-bold dark:text-white">Описание проекта:</label>
                    <div className="flex flex-row grow">
                        <textarea id="message" rows={6} className="flex h-full p-2.5 mb-5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="описание проекта..." value={projectInformation} onChange={e => {setProjectInformation(e.target.value)}}></textarea>
                    </div>
                    {/*<label htmlFor="message" className="block mb-2 text-md text-black font-bold">Список задач:</label>*/}
                    <label htmlFor="project-tasks-lable" className="block my-2 text-md font-bold text-black">Список задач:</label>
                    <div className="bg-white p-3 rounded-lg block w-full p-2.5  text-black">
                        <div className="text-center">
                            <h1 className="block mb-2 text-md font-bold text-black"></h1>
                            <div className="flex">
                                <textarea id="message" rows={6}
                                          className="flex flex-row grow border-b-2 border-gray-500 text-black"
                                    placeholder="Добавить задачу..."
                                    value={currentTaskFieldValue}
                                    onChange={e => {setCurrentTaskFieldValue(e.target.value)}}
                                />
                               <div>
                                   <button
                                       className="ml-2 border-2 border-green-500 p-2 text-green-500 hover:text-white hover:bg-green-500 rounded-lg flex"
                                       onClick={onAddTask}
                                   >
                                       <svg className="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg>
                                       <span>Добавить</span>
                                   </button>
                               </div>
                            </div>
                        </div>
                        <div className="mt-8">
                            <ul>
                                { tasks.length ? tasks.map((task, index) => (
                                    <li className="p-2 rounded-lg" key={index} >
                                        <div className="flex align-middle flex-row justify-between">
                                            <div className="p-2">
                                                <p className="text-lg">{task.name}</p>
                                            </div>
                                            <div className="flex flex-row items-start">
                                                <button
                                                    className="text-blue-500 border-2 border-blue-500 p-2 mr-3 rounded-lg"
                                                    onClick={e => onEditTask(index, task.name)}>
                                                    <svg className="h-6 w-6 text-blue-500 hover:!text-blue-700" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                                                    <span></span>
                                                </button>
                                                <button
                                                    className="text-red-500 border-2 border-red-500 p-2 rounded-lg"
                                                    onClick={e => onDeleteTask(index)}>
                                                    <svg className="h-6 w-6 text-red-500 hover:!text-red-700" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                                    <span></span>
                                                </button>
                                            </div>
                                        </div>
                                        <hr className="mt-2"/>
                                    </li>
                                )) : 'Список задач пуст'}
                            </ul>
                        </div>
                    </div>
                    { !isCreateNew ? <label className="block mb-2 text-md font-bold text-gray-900 dark:text-white" htmlFor="loaded_files">Загруженные данные:</label> : <></>}
                    {
                        selectedProjectFiles.map((file, index) =>(
                            <div key={index}
                                 className="mb-2 text-md text-black cursor-pointer hover:underline"
                                 onClick={e => {onDeleteLoadedFileClick(index)}}>
                                <img className="inline mr-3" width={'24px'} height={'24px'} src={fileDelete } alt="icon"/>
                                {file.name}
                            </div>
                        )) || filesLoadedRender
                    }
                    <label className="block mb-2 text-md font-bold text-gray-900 dark:text-white" htmlFor="upload_files">Загрузка исходных данных:</label>
                    <input className="block w-full text-md p-2.5 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_project_upload" id="file_project_upload" type="file" multiple onChange={onSelectFile}/>
                    {
                        projectFiles.length ? <div className="mb-2 text-md text-black font-bold">Будут загружены (нажать на файл для удаления):</div> : <></>
                    }
                    {
                        projectFiles.map((file, index) =>(
                            <div key={index}
                                 className="mb-2 text-md text-black cursor-pointer hover:underline"
                                 onClick={e => {onDeleteSelectedFileClick(index)}}>
                                    <img className="inline mr-3" width={'24px'} height={'24px'} src={fileDelete } alt="icon"/>
                                    {file.name}
                            </div>
                        )) || filesRerender
                    }
                    <div className="mt-1 text-md text-gray-500 dark:text-gray-300" id="user_avatar_help">Загрузк исходных данных (rar|zip|pdf|png|jpg|jpeg|docx|doc)</div>
                    <div className="relative pt-5">
                        <ButtonSpinner ref={saveBtnRef} name={isCreateNew ? 'Сохранить' : 'Изменить'} processName={'Сохранение'} onProceedClick={onSaveClick}  />
                        <>{saveBtnRef.current ? saveBtnRef.current.setDisabled(Boolean(!projectName.length || !projectInformation.length || !tasks.length || !projectFiles.length && isCreateNew || !isCreateNew && (!projectFiles.length && tasks.length && !selectedProjectFiles.length)) ) : ''}</>
                        {/*<button className="absolute top-0 right-0 bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSaveClick}>*/}
                        {/*    Создать*/}
                        {/*</button>*/}
                    </div>
                </div>

            </div>
        </>
    );
};

export default CreateProject;