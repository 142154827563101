import React, {useEffect, useState} from 'react';
import '../../tailwind.css';
import {store} from "../../store/store";
import {INVALID_FILTER_VALUE, INVALID_ID} from "../../services/constants";
import {IProject, IProjectTask} from "../Projects/ListProjects";
import {handleRequest} from "../../services/http-wrapper";

const Kanban = () => {
    const {name, email, team} = store.getState().authReducer.userInfo;
    const [projects, setProjects] = useState<IProject[]>([])
    const [tasks, setTasks] = useState<IProjectTask[]>([])
    const [selectedProject, setSelectedProject] = useState<React.ChangeEvent<HTMLSelectElement>|null>(null);

    const loadProjects = async () => {
        const response = await handleRequest('projects/findLinkedUser');

        setProjects(response)
    }

    useEffect(() => {
        loadProjects()
    }, []);

    const onSelectProject = (e: React.ChangeEvent<HTMLSelectElement>|undefined = undefined) => {
        let currentElement: EventTarget & HTMLSelectElement | undefined  = undefined;

        if(e) {
            setSelectedProject(e);
            currentElement = e.target
        }
        else if(selectedProject) {
            currentElement = selectedProject.target
        }
        else {
            return;
        }

        if(currentElement.value === INVALID_FILTER_VALUE) {
            setSelectedProject(null);
            setTasks([]);
        }
        else {
            setSelectedProject(e ? e : selectedProject);
            const project = projects.find(project => project.id === Number(currentElement?.selectedOptions[0].dataset.id)) ?? null;
            if(project) {
                setTasks(project.tasks);
            }
        }
    }


    return (
        <div className="bg-gray-200 flex flex-col grow">
            <div className="h-16 w-full bg-black bg-opacity-50">
                <div className="w-full h-full flex justify-center items-center text-sm">
                    <div className="flex h-full w-full items-center justify-center">
                        <select id="myProjects"
                                className="bg-gray-50 w-1/2 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={Number(selectedProject?.target.selectedOptions[0].dataset.id) ?? INVALID_FILTER_VALUE}
                                onChange={onSelectProject}>
                            <option value={INVALID_FILTER_VALUE}>Выбор проекта</option>
                            {
                                projects.map((project, index) =>(
                                    <option key={index}
                                            value={project.id}
                                            data-index={index}
                                            data-id={project.id}
                                            data-name={project.name}
                                            data-information={project.information}
                                            data-files={project.files}>
                                        {project.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-4 gap-5">

                <div className="bg-white rounded px-2 py-2">

                    <div className="flex flex-row justify-between items-center mb-2 mx-1">
                        <div className="flex items-center">
                            <h2 className="bg-red-100 text-sm w-max px-1 rounded mr-2 text-gray-700">To-do</h2>
                            <p className="text-gray-400 text-sm">3</p>
                        </div>
                        <div className="flex items-center text-gray-300">
                            <p className="mr-2 text-2xl">---</p>
                            <p className="text-2xl">+</p>
                        </div>
                    </div>

                    <div className="grid grid-rows-1 gap-2">
                        {tasks.map((task, index) => (
                            <div className="p-2 rounded shadow-sm border-gray-100 border-2">
                                <h3 className="text-xs mb-3 text-gray-700">{task.name}</h3>
                                <p className="bg-red-100 text-xs w-max p-1 rounded mr-2 text-gray-700">To-do</p>
                                <div className="flex flex-row items-center mt-2">
                                    <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>
                                    <a href="#" className="text-xs text-gray-500">{task.user?.name ?? 'Не определено'}</a>
                                </div>
                                <p className="text-xs text-gray-500 mt-2">2</p>
                            </div>
                        ))}


                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Review survey results</h3>*/}
                        {/*    <p className="bg-red-100 text-xs w-max p-1 rounded mr-2 text-gray-700">To-do</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">1</p>*/}
                        {/*</div>*/}

                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Research video marketing</h3>*/}
                        {/*    <p className="bg-red-100 text-xs w-max p-1 rounded mr-2 text-gray-700">To-do</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">3</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className="flex flex-row items-center text-gray-300 mt-2 px-1">
                        <p className="rounded mr-2 text-2xl">+</p>
                        <p className="pt-1 rounded text-sm">New</p>
                    </div>
                </div>


                <div className="bg-white rounded px-2 py-2">

                    <div className="flex flex-row justify-between items-center mb-2 mx-1">
                        <div className="flex items-center">
                            <h2 className="bg-yellow-100 text-sm w-max px-1 rounded mr-2 text-gray-700">WIP</h2>
                            <p className="text-gray-400 text-sm">2</p>
                        </div>
                        <div className="flex items-center text-gray-300">
                            <p className="mr-2 text-2xl">---</p>
                            <p className="text-2xl">+</p>
                        </div>
                    </div>

                    <div className="grid grid-rows-2 gap-2">

                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Blog post live</h3>*/}
                        {/*    <p className="bg-yellow-100 text-xs w-max p-1 rounded mr-2 text-gray-700">WIP</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">Jun 21, 2019</p>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">2</p>*/}
                        {/*</div>*/}

                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Email campaign</h3>*/}
                        {/*    <p className="bg-yellow-100 text-xs w-max p-1 rounded mr-2 text-gray-700">WIP</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">Jun 21, 2019 &#10141; Jun 21, 2019</p>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">1</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className="flex flex-row items-center text-gray-300 mt-2 px-1">
                        <p className="rounded mr-2 text-2xl">+</p>
                        <p className="pt-1 rounded text-sm">New</p>
                    </div>
                </div>


                <div className="bg-white rounded px-2 py-2">

                    <div className="flex flex-row justify-between items-center mb-2 mx-1">
                        <div className="flex items-center">
                            <h2 className="bg-green-100 text-sm w-max px-1 rounded mr-2 text-gray-700">Complete</h2>
                            <p className="text-gray-400 text-sm">4</p>
                        </div>
                        <div className="flex items-center">
                            <p className="text-gray-300 mr-2 text-2xl">---</p>
                            <p className="text-gray-300 text-2xl">+</p>
                        </div>
                    </div>

                    <div className="grid grid-rows-2 gap-2">
                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Morning emails and to-do list</h3>*/}
                        {/*    <p className="bg-green-100 text-xs w-max p-1 rounded mr-2 text-gray-700">Complete</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">Jun 21, 2019</p>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">1</p>*/}
                        {/*</div>*/}

                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Blog post</h3>*/}
                        {/*    <p className="bg-green-100 text-xs w-max p-1 rounded mr-2 text-gray-700">Complete</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">Jun 20, 2019</p>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">5</p>*/}
                        {/*</div>*/}

                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Reconcile accounts</h3>*/}
                        {/*    <p className="bg-green-100 text-xs w-max p-1 rounded mr-2 text-gray-700">Complete</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">Jun 19, 2019</p>*/}
                        {/*    <p className="text-xs text-gray-600 mt-2">4</p>*/}
                        {/*</div>*/}

                        {/*<div className="p-2 rounded shadow-sm border-gray-100 border-2">*/}
                        {/*    <h3 className="text-sm mb-3 text-gray-700">Website AB test</h3>*/}
                        {/*    <p className="bg-green-100 text-xs w-max p-1 rounded mr-2 text-gray-700">Complete</p>*/}
                        {/*    <div className="flex flex-row items-center mt-2">*/}
                        {/*        <div className="bg-gray-300 rounded-full w-4 h-4 mr-3"></div>*/}
                        {/*        <a href="#" className="text-xs text-gray-500">Sophie Worso</a>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-xs text-gray-500 mt-2">Jun 18, 2019</p>*/}
                        {/*    <p className="text-xs text-gray-600 mt-2">3</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className="flex flex-row items-center text-gray-300 mt-2 px-1">
                        <p className="rounded mr-2 text-2xl">+</p>
                        <p className="pt-1 rounded text-sm">New</p>
                    </div>
                </div>

                <div className="bg-white rounded px-2 py-2">

                    <div className="flex flex-row justify-between items-center mb-2 mx-1">
                        <div className="flex items-center">
                            <h2 className="bg-gray-200 w-4 px-1 rounded mr-2 text-sm text-center">.</h2>
                            <p className="text-gray-400 text-sm">0</p>
                        </div>
                        <div className="flex items-center text-gray-300">
                            <p className="mr-2 text-2xl">---</p>
                            <p className="text-2xl">+</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center text-gray-300 mt-2 px-1">
                        <p className="rounded mr-2 text-2xl">+</p>
                        <p className="pt-1 rounded text-sm">New</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kanban;