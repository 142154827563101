export const ERROR = -1;
export const INVALID_ID = 0;
export const INVALID_FILTER_VALUE = "-1";

export const ALERT_ERROR = 1;
export const ALERT_WARNING = 2;
export const ALERT_INFO = 3;
export const ALERT_NEUTRAL = 4;
export type ALERT_RANGE = 1|2|3|4

export interface IAlertData {
    alertType: ALERT_RANGE,
    delay?: number
}

