import http from './http-common';
import {handleRequest} from "./http-wrapper";

const login = async(login: string, password: string) => {
    return await handleRequest('/auth/login', {
                                    email: login,
                                    password
                                },
                                false,
                                false);
}

const auth = async() => {
    return handleRequest( '/auth')
}

const logout = async () => {
    return await http.post('/auth/logout' );
}

export {login, auth, logout};