import styled from "@emotion/styled";
import { motion } from "framer-motion";
import bg from "../../images/bg.png";

const StyledDesktop = styled(motion.div)`
  position: relative;
  width: 100vw;
  height: 100vh;
  flex: 1;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

export default StyledDesktop;
