import styled from "@emotion/styled";
import { motion } from "framer-motion";


export const StyledAppIcon = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAppIconContent = styled.div`
  width: 110px;
`;
export const StyledAppIconIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledAppIconText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  text-align: center;
  color:black;
  padding-top: 5px;

`;