import React from 'react';
import {StyledAppIcon, StyledAppIconIcon, StyledAppIconContent, StyledAppIconText} from "./AppIcon.style";
import {useDragControls} from "framer-motion";

const AppIcon = ({
                     icon,
                     name,
                     app,
                     zIndex,
                     appsManager,
                     constraintsRef,
                     ...rest
                 }) => {
    const controls = useDragControls();
    const startDrag = (event) => {
        controls.start(event);
        appsManager.focusApp({name});
    };

    const height = 100;
    const width = 130;

    return (<StyledAppIcon
        drag
        style={{zIndex, width, height}}
        dragControls={controls}
        dragConstraints={constraintsRef}
        dragListener={false}
        dragElastic={0.5}
        dragMomentum={false}
        transition={{type: "spring", mass: 0.6, stiffness: 200, damping: 20}}
        onPointerDown={startDrag}
    >
        <StyledAppIconContent
            {...rest}
            initial={{opacity: 0, y: "100%"}}
            animate={{opacity: 1, y: "0%"}}
            exit={{opacity: 0, y: "100%"}}>

            <StyledAppIconIcon>
                <img width={'64px'} height={'64px'} src={icon} alt="icon"/>
            </StyledAppIconIcon>
            <StyledAppIconText><p>{name}</p></StyledAppIconText>
        </StyledAppIconContent>
    </StyledAppIcon>);

};

export default AppIcon;
