import React from 'react';
import '../../tailwind.css';
import {store} from "../../store/store";

const Profile = () => {
    const {name, email, team} = store.getState().authReducer.userInfo

    return (
        <div className="bg-gray-200 flex flex-col grow">
            <div className="p-10 [&>*]:font-sans flex flex-col grow">
                <label htmlFor="project-name-lable" className="block mb-2 text-md font-bold text-black">ФИО:</label>
                <input type="text" id="project-name-field" disabled className="font-sans mb-5 border text-md rounded-lg block w-full p-2.5 disabled  text-black" placeholder="" value={name}  />
                <label htmlFor="message" className="block mb-2 text-md text-black font-bold dark:text-white">Email:</label>
                <input type="text" id="project-email-field" disabled className="font-sans mb-5 border text-md rounded-lg block w-full p-2.5  text-black" placeholder="" value={email}/>
                <label htmlFor="message" className="block mb-2 text-md text-black font-bold dark:text-white">Группа:</label>
                <input type="text" id="project-team-field" disabled className="font-sans mb-5 border text-md rounded-lg block w-full p-2.5  text-black" placeholder="" value={team?.name ??  'Нет группы'}/>
            </div>

        </div>
    );
};

export default Profile;